/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-cycle */
import {FilterModel, FilterType, IFilterModel, IQueryParamsFilter} from '../types/galleryTypes';
import {PriceFilterModel} from '../models/PriceFilterModel';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {safeDecode} from '../viewerScript/utils';
import {Experiments} from '../constants';
import {ISorting} from '../types/sorting';
import {queryToString} from '@wix/wixstores-client-storefront-sdk/dist/src/services/utils';

export const enum QueryParamActiveOptionStrategy {
  SingleCollection,
  MultiValue,
  Price,
}

export const getFilterTypeToActiveOptionStrategy = (usingMultiCollectionFilters: boolean) => ({
  [FilterType.COLLECTION]: usingMultiCollectionFilters
    ? QueryParamActiveOptionStrategy.MultiValue
    : QueryParamActiveOptionStrategy.SingleCollection,
  [FilterType.LIST_OPTION]: QueryParamActiveOptionStrategy.MultiValue,
  [FilterType.CUSTOM_COLLECTION]: QueryParamActiveOptionStrategy.MultiValue,
  [FilterType.COLOR_OPTION]: QueryParamActiveOptionStrategy.MultiValue,
  [FilterType.PRICE]: QueryParamActiveOptionStrategy.Price,
});

export enum DefaultQueryParamKeys {
  Sort = 'sort',
  Page = 'page',
  ScrollToProduct = 'scrollToProduct',
}

export const DefaultFilterId = 'default';

interface IQueryParamsObject {
  paramTitle: string;
  paramValue: string;
  isShown: boolean;
}

export type QueryParamsServiceConfig = {
  siteStore: SiteStore;
  isMobile: boolean;
  isCategoryPage: boolean;
  usingMultiCollectionFilters: boolean;
  shouldUseNewApplyFilterQueryParams: boolean;
};

export class GalleryQueryParamsService {
  private readonly siteStore: SiteStore;
  private readonly isCategoryPage: boolean;
  private readonly usingMultiCollectionFilters?: boolean;
  private readonly shouldUseNewApplyFilterQueryParams: boolean;

  constructor(config: QueryParamsServiceConfig) {
    const {siteStore, isCategoryPage, usingMultiCollectionFilters, shouldUseNewApplyFilterQueryParams} = config;
    this.siteStore = siteStore;
    this.isCategoryPage = isCategoryPage;
    this.usingMultiCollectionFilters = usingMultiCollectionFilters;
    this.shouldUseNewApplyFilterQueryParams = shouldUseNewApplyFilterQueryParams;
  }

  private readonly setAndUpdateQueryParams = ({paramTitle, paramValue, isShown}: IQueryParamsObject) => {
    /* istanbul ignore else: no else needed */
    if (paramValue !== undefined) {
      if (isShown) {
        this.siteStore.location.queryParams.add({[paramTitle]: paramValue});
      } else {
        this.siteStore.location.queryParams.remove([paramTitle]);
      }
    }
  };

  private readonly getDecodedSearchParams = (searchParams: {
    [paramKey: string]: string;
  }): {[paramKey: string]: string} => {
    return Object.keys(searchParams).reduce((res, key) => {
      const decodedKey = this.siteStore.experiments.enabled(Experiments.FixQueryParamSpecialCharDecode)
        ? safeDecode(key) || key
        : safeDecode(key);

      const decodedValue = this.siteStore.experiments.enabled(Experiments.UseNewFiltersQueryParamDecoder)
        ? searchParams[key]
        : safeDecode(searchParams[key]);

      const isParamValid = decodedKey && decodedValue;

      return isParamValid
        ? {
            ...res,
            [decodedKey]: decodedValue,
          }
        : res;
    }, {});
  };

  private readonly removeQueryParam = (paramTitle: string | string[]) => {
    const paramTitles = Array.isArray(paramTitle) ? paramTitle : [paramTitle];
    this.removeQueryParamUrl(paramTitles);
  };

  private readonly removeQueryParamUrl = (paramTitles: string[]): void => {
    this.siteStore.location.queryParams.remove(paramTitles);
  };

  public readonly updateFiltersQueryParams = ({
    filterModel,
    mainCollectionId,
  }: {
    filterModel: IFilterModel;
    mainCollectionId: string;
    forceUpdateFromUrl: boolean;
  }) => {
    const {title, value} = this.getActiveFilterOptionByFilters(filterModel, mainCollectionId);

    if (this.shouldUseNewApplyFilterQueryParams) {
      this.updateQueryParamAndResetPage(title, value);
    } else {
      this.updatePageQueryParam();
      this.setAndUpdateQueryParams({paramTitle: title, paramValue: value, isShown: !!value});
    }
  };

  public updateQueryParamAndResetPage = (title: string, value: string | null) => {
    let query = {...this.siteStore.location.query};
    const baseUrl = `/${this.siteStore.location.path.join('/')}`;
    if (value) {
      query[title] = value;
      const {page, ...filteredQuery} = query;
      query = filteredQuery;
    } else {
      const {[title]: _, page, ...filteredQuery} = query;
      query = filteredQuery;
    }

    const queryString = queryToString(query);
    const url = queryString.length > 0 ? `${baseUrl}?${queryString}` : baseUrl;

    this.siteStore.location.to(url);
  };

  public getActiveFilterOptionByFilters(
    filterModel: IFilterModel,
    mainCollectionId: string
  ): {title: string; value: string} {
    let activeFilterOption: string = '';
    const delimiter = ',';
    const strategy = getFilterTypeToActiveOptionStrategy(this.usingMultiCollectionFilters);

    switch (strategy[filterModel.filterType]) {
      case QueryParamActiveOptionStrategy.SingleCollection: {
        if (filterModel.activeOptions === mainCollectionId) {
          activeFilterOption = `All`;
        } else {
          const filterOption = filterModel.options.find((option) => option.key === filterModel.activeOptions);
          activeFilterOption = this.siteStore.experiments.enabled(Experiments.UseNewFiltersQueryParamEncoder)
            ? encodeURIComponent(filterOption.value)
            : filterOption.value;
        }
        break;
      }
      case QueryParamActiveOptionStrategy.MultiValue: {
        const filterOptions = filterModel.options.filter(
          (option) => filterModel.activeOptions.indexOf(option.key) > -1
        );
        if (this.siteStore.experiments.enabled(Experiments.UseNewFiltersQueryParamEncoder)) {
          activeFilterOption = filterOptions.map((filter) => encodeURIComponent(filter.value)).join(delimiter);
        } else if (filterOptions.length === 1) {
          activeFilterOption = filterOptions[0].value;
        } else if (filterOptions.length === 0) {
          this.removeQueryParam(filterModel.title);
        } else {
          activeFilterOption = filterOptions.map((filter) => filter.value).join(delimiter);
        }
        break;
      }
      case QueryParamActiveOptionStrategy.Price: {
        activeFilterOption = `${(filterModel.activeOptions as PriceFilterModel).minPrice}-${
          (filterModel.activeOptions as PriceFilterModel).maxPrice
        }`;
        break;
      }
    }

    return {title: filterModel.title, value: activeFilterOption};
  }

  public readonly getFiltersQueryParams = (filterModels: FilterModel[]): IQueryParamsFilter[] => {
    const decodedSearchParams = this.getDecodedSearchParams(this.siteStore.location.query);
    const activeFilters = [];

    const delimiter = ',';

    filterModels.forEach((filter) => {
      if (decodedSearchParams[filter.title]) {
        const paramsFilter = decodedSearchParams[filter.title];
        switch (filter.filterType) {
          case FilterType.LIST_OPTION:
          case FilterType.CUSTOM_COLLECTION:
          case FilterType.COLOR_OPTION:
          case FilterType.COLLECTION: {
            activeFilters.push({
              key: filter.title,
              value: paramsFilter,
              filterId: filter.filterId,
            });

            break;
          }
          case FilterType.PRICE: {
            const priceValue = paramsFilter.split('-');
            activeFilters.push({
              key: filter.title,
              value: `${priceValue[0]}${delimiter}${priceValue[1]}`,
              filterId: filter.filterId,
            });
          }
        }
      }
    });

    return activeFilters.length > 0 ? activeFilters : null;
  };

  public readonly getQueryParam = (paramTitle: string): string => {
    const queryParams = this.siteStore.location.query;
    return queryParams[paramTitle];
  };

  public readonly clearAllFiltersQueryParams = (paramTitles: string[]) => {
    if (!this.siteStore.experiments.enabled(Experiments.ClearFiltersInASingleCall)) {
      this.updatePageQueryParam();
    }
    if (this.siteStore.experiments.enabled(Experiments.ClearFiltersInASingleCall)) {
      this.removeQueryParam([DefaultQueryParamKeys.Page, ...paramTitles]);
    } else {
      paramTitles.forEach((title) => {
        this.removeQueryParam(title);
      });
    }
  };

  public readonly clearAllQueryParams = (filterQueryParams: string[]) => {
    const query = {...this.siteStore.location.query};
    const baseUrl = `/${this.siteStore.location.path.join('/')}`;
    const allParams = [
      ...filterQueryParams,
      DefaultQueryParamKeys.Page,
      DefaultQueryParamKeys.Sort,
      DefaultQueryParamKeys.ScrollToProduct,
    ];

    const filteredQuery = Object.fromEntries(Object.entries(query).filter(([key]) => !allParams.includes(key)));

    const queryString = queryToString(filteredQuery);
    const url = queryString.length > 0 ? `${baseUrl}?${queryString}` : baseUrl;

    this.siteStore.location.to(url);
  };

  public getPageQueryParam = (): number => {
    const page = +this.getQueryParam(DefaultQueryParamKeys.Page);
    return page || 1;
  };

  public getScrollToProductQueryParam = () => {
    return this.getQueryParam('scrollToProduct');
  };

  public readonly updatePageQueryParam = (page?: number): void => {
    if (page > 1) {
      this.setAndUpdateQueryParams({
        paramTitle: DefaultQueryParamKeys.Page,
        paramValue: page.toString(),
        isShown: true,
      });
    } else {
      this.removeQueryParam(DefaultQueryParamKeys.Page);
    }
  };

  public readonly updateScrollToProduct = (productUrlPart: string) => {
    this.setAndUpdateQueryParams({
      paramTitle: DefaultQueryParamKeys.ScrollToProduct,
      paramValue: productUrlPart,
      isShown: true,
    });
  };

  public readonly getUrl = (): string => {
    const prefix = this.isCategoryPage ? (this.siteStore.location as any).prefix : undefined;
    return [this.siteStore.location.baseUrl, prefix, ...this.siteStore.location.path].filter((i) => i).join('/');
  };

  public readonly getUrlWithCustomPageParamForSeo = (page: number): string => {
    let url = this.getUrl();

    if (page > 1) {
      url += `?page=${page}`;
    }

    return url;
  };

  public readonly updateSortQueryParams = (selectedSort: ISorting) => {
    if (selectedSort.id === DefaultFilterId) {
      this.shouldUseNewApplyFilterQueryParams
        ? this.updateQueryParamAndResetPage(DefaultQueryParamKeys.Sort, null)
        : this.removeQueryParam(DefaultQueryParamKeys.Sort);
    } else {
      this.shouldUseNewApplyFilterQueryParams
        ? this.updateQueryParamAndResetPage(DefaultQueryParamKeys.Sort, selectedSort.id)
        : this.setAndUpdateQueryParams({
            paramTitle: DefaultQueryParamKeys.Sort,
            paramValue: selectedSort.id,
            isShown: true,
          });
    }
  };
}
